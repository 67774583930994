// https://owlcarousel2.github.io/OwlCarousel2/demos/demos.html
$('document').ready(function(){
	// Homepage, About
	$('.carousel-partner').owlCarousel({
		loop:true,
		autoplay:true,
		autoplayTimeout:3000,
		autoplaySpeed:1000,
		autoplayHoverPause: false,
		margin:20,
		nav:false,
		dots:true,
		items: 5,
	});

	// About
	$('.carousel-testimonial').owlCarousel({
		loop:true,
		autoplay:false,
		margin:20,
		nav:true,
		dots:false,
		responsive : {
			0 : {
				stagePadding: 44,
				items: 1,
			},
			480 : {
				stagePadding: 44,
				items: 2,
			},
			960 : {
				stagePadding: 150,
				items: 2,
			},
			1200 : {
				stagePadding: 150,
				items: 3,
			},
			1400 : {
				stagePadding: 150,
				items: 4,
			},
		}
	});

	$('.carousel-partner2').owlCarousel({
		loop:true,
		autoplay:true,
		autoplayTimeout:3000,
		autoplaySpeed:1000,
		autoplayHoverPause: false,
		margin:20,
		nav:false,
		dots:true,
		items: 1,
	});

	$('.owl-prev').text("").append('<span><</span>');
	$('.owl-next').text("").append('<span>></span>');
});
