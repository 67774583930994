$('document').ready(function(){

	// Header sticky
	var scroll = $(window).scrollTop();
	if (scroll >= 10) {
		$(".header").addClass("sticky");
	} else {
		$(".header").removeClass("sticky");
	}

	$(window).scroll(function() {
		var scroll = $(window).scrollTop();
		if (scroll >= 10) {
			$(".header").addClass("sticky");
		} else {
			$(".header").removeClass("sticky");
		}
	});

	// Hamburger
	$('html').on('click', '.hamburger', function(event){
		event.preventDefault();
		$('body').addClass('nav-active');
		if($(this).attr('aria-expanded')=='false') {
			$(this).attr('aria-expanded', 'true');
		} else {
			$(this).attr('aria-expanded', 'false');
		}
	});

	// Close nav
	$('html').on('click', '.close-bio, .fade-overlay, .inner-modal, .close-nav', function(event){
		event.preventDefault();
		$('.bio-modal').fadeOut();
		$('.fade-overlay').fadeOut();
		$('body').removeClass('nav-active');
	});

	// Teams Modal
	$('html').on('click', '.trigger-bio', function(event){
		event.preventDefault();
		$('.bio-modal').fadeOut();
		$(this).parent().find('.bio-modal').fadeIn();
		$('.fade-overlay').fadeIn();
	});

	// Sub nav
	$('body').on('click', '.slidenav .menu-item-has-children > a:not(.menu-item-has-children li > a)', function(e){
		e.preventDefault();
		var navlabel = $(this).find('span').text();
		$(this).siblings('ul.sub-menu').find('.back-arrow').text(navlabel);
		$(this).siblings('ul.sub-menu').addClass('active');
		$('.mobile-navigation .button').hide();
	});
	$('.slidenav ul.sub-menu .back-arrow').click(function(){
		$(this).closest('ul.sub-menu').removeClass('active');
		$('.mobile-navigation .button').show();
	});

	// Floating Labels for CF7

	//https://webdesign.tutsplus.com/tutorials/floating-labels-for-contact-form-7--cms-36327

	const formsWithAnimatedLabels = document.querySelectorAll(".form-with-animated-labels");
	const focusedClass = "focused";

	for (const form of formsWithAnimatedLabels) {
		const formControls = form.querySelectorAll(
			'[type="text"], [type="email"], [type="tel"], textarea'
			);
		for (const formControl of formControls) {
			formControl.addEventListener("focus", function () {
				this.parentElement.nextElementSibling.classList.add(focusedClass);
			});

			formControl.addEventListener("blur", function () {
				if (!this.value) {
					this.parentElement.nextElementSibling.classList.remove(focusedClass);
				}
			});
		}

		form.parentElement.addEventListener("wpcf7mailsent", function () {
			const labels = document.querySelectorAll(".focused");
			for (const label of labels) {
				label.classList.remove(focusedClass);
			}
		});
	}

	/*
	// Drawing Lines
	var svg = document.querySelector('#Path_19373');

	var tl = new TimelineMax({repeat:1, yoyo:false});

	//create a timeline with 2 tweens that draw 2 separate strokes
	//tl.add(createLineTween(svg));

	//this function creates a single tween that animates the stroke of an svg
	function createLineTween(svg) {
		var pathObject = {length:0, pathLength:svg.getTotalLength()};
		var tween = TweenLite.to(pathObject, 2, {length:pathObject.pathLength, onUpdate:drawLine, onUpdateParams:[pathObject, svg], immediateRender:true});
		return tween;
	};

	ScrollTrigger.create({
		trigger: "#line",
		start: "top center",
		pin: false,
		scrub: true,
		markers: true,
		invalidateOnRefresh: true,
		once: true,
		onEnter: () => createLineTween(svg)
	});


	//update stroke
	function drawLine(obj, svg) {
		svg.style.strokeDasharray = [obj.length, obj.pathLength].join(' ');
	};
	*/
});

$(window).on('load', function(){
	setTimeout(function() {
		$('select').selectric();
	}, 100);
});
